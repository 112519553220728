import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import StorybookEmbed from "../../../components/StorybookEmbed.jsx";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentTextArea.figmaUrl.website} codeUrl={checklists.componentTextArea.codeUrl.website} checklists={checklists.componentTextArea.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Textarea allow user input. The border should light up simply and clearly indicating which field the user is currently editing.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Textarea } from "@legion-ui/core";
`}</code></pre>
    <StorybookEmbed storybookId="component-textarea--default" mdxType="StorybookEmbed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      